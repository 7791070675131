<template>
  <div>
    <!-- text fields -->
    <v-card class="mx-auto pb-4 rounded-xl" max-width="600" style="z-index: 1000">
      <v-card-title class="mx-auto justify-center flex-column py-8 special-card-title">
        <a href="/" class="logo-wrapper-alt">
          <img height="36" src="/img/DNA_Symbol.png" />
          <v-img contain height="36" src="/img/logo.svg"></v-img>
        </a>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-container class="text-center" v-if="fetchingInvitation">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Invitation ...</p>
      </v-container>
      <div v-else-if="invitationFetched && invitation != null">
        <div v-if="invitation.isAccepted" class="text-center mb-6 mt-6">
          <h2 class="mb-2">
            <i class="fad fa-memo-circle-check fa-4x success--text mb-4"></i>
            <br />
            Invitation accepted!
          </h2>
          <v-btn
            color="info"
            link
            to="/login"
            style="padding-right: 20px !important; padding-left: 20px !important"
          >
            <i class="far fa-arrow-right-to-bracket mr-2"></i>Login
          </v-btn>
        </div>
        <div v-else>
          <v-form v-model="valid" class="pa-3 mt-0 pt-0">
            <h2 class="secondary--text text-center mb-2">
              {{ timeGreetings }} {{ invitation.firstName }} {{ invitation.lastName }}
            </h2>
            <h3 class="text--secondary text-center mb-4">
              You're invited to join Concordia!<br />Please complete the form below to proceed!
            </h3>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="Email"
                    id="userEmail"
                    v-model="invitation.email"
                    readonly
                    hide-details
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.length(6), allRules.noWhiteSpaces]"
                    label="Password"
                    placeholder="Password"
                    id="userPassword"
                    v-model="accountDetails.password"
                    :append-icon="passwordIsVisible ? 'far fa-eye' : 'far fa-eye-slash'"
                    :type="passwordIsVisible ? 'text' : 'password'"
                    required
                    @click:append="passwordIsVisible = !passwordIsVisible"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field-alt
                    :rules="[
                      allRules.required,
                      allRules.noWhiteSpaces,
                      allRules.length(6),
                      (v) =>
                        accountDetails.password === accountDetails.confirmPassword ||
                        `Passwords do not match`,
                    ]"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    id="userConfirmPassword"
                    v-model="accountDetails.confirmPassword"
                    :append-icon="passwordIsVisible ? 'far fa-eye' : 'far fa-eye-slash'"
                    :type="passwordIsVisible ? 'text' : 'password'"
                    required
                    @click:append="passwordIsVisible = !passwordIsVisible"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
              <v-btn
                block
                color="info"
                :disabled="!valid || acceptingInvitation"
                :loading="acceptingInvitation"
                @click="acceptInvitation()"
              >
                <i class="fal fa-check mr-2" style="line-height: 0"></i>
                Accept Invitation
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </v-card>
    <div class="img-bg"></div>
    <div class="bg-confetti">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 320">
        <path
          fill="rgba(71, 68, 67, 0.03)"
          fill-opacity="1"
          d="M0,192L48,160C96,128,192,64,288,64C384,64,480,128,576,170.7C672,213,768,235,864,229.3C960,224,1056,192,1152,197.3C1248,203,1344,245,1392,266.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import userInvitationService from "../services/user-invitation-service";
export default {
  name: "invitation-accept",
  data: () => ({
    hourNow: 0,
    invitationId: null,
    invitation: null,
    valid: false,
    passwordIsVisible: false,
    fetchingInvitation: false,
    invitationFetched: false,
    acceptingInvitation: false,
    accountDetails: {
      id: null,
      password: "",
      confirmPassword: "",
    },
  }),
  created() {
    this.invitationId = this.$route.params.id;
    this.accountDetails.id = this.invitationId;
    this.getInvitationStatus();
  },
  mounted() {
    this.updateNow();
    setInterval(this.updateNow.bind(this), 1000);
  },
  methods: {
    updateNow() {
      this.hourNow = new Date().getHours();
    },
    getInvitationStatus() {
      this.fetchingInvitation = true;
      userInvitationService
        .getById(this.invitationId)
        .then((resp) => {
          this.fetchingInvitation = false;
          this.invitationFetched = true;
          this.invitation = resp.data;
        })
        .catch((err) => {
          this.fetchingInvitation = false;
          this.invitationFetched = true;
          this.$handleError(err);
        });
    },
    acceptInvitation() {
      this.acceptingInvitation = true;
      userInvitationService
        .accept(this.accountDetails)
        .then((resp) => {
          this.acceptingInvitation = false;
          this.invitation = resp.data;
        })
        .catch((err) => {
          this.acceptingInvitation = false;
          this.$handleError(err);
        });
    },
  },
  computed: {
    timeGreetings() {
      if (this.hourNow > 18) {
        return "Good evening,";
      } else if (this.hourNow > 12) {
        return "Good afternoon,";
      } else if (this.hourNow > 0) {
        return "Good morning,";
      } else {
        return "Welcome,";
      }
    },
  },
};
</script>

<style lang="scss">
.logo-wrapper-alt.toolbar-logo {
  display: none !important;
}

.v-main__wrap {
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-bg {
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 0.04;
  z-index: 1;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  background-image: url("/img/art/urban-105.png");
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: contain;
  height: 100%;
  animation: animatedBackgroundHorizontal 300s linear infinite;
  filter: invert(1);
}
@keyframes animatedBackgroundHorizontal {
  from {
    background-position: left bottom;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: 4940px bottom;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: 10000px 10000px;
  }
}
.bg-confetti {
  display: block;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  // background: url("/img/bg icons confetti.png?v1");
  background-repeat: repeat;
  background-position: 0 0;
  background-size: auto 100%; /*adjust s value for speed*/
  animation: animatedBackground 500s linear infinite;
  opacity: 0.87;
}

.bg-confetti svg {
  display: block;
  position: fixed;
  z-index: -1;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

// .special-card-title {
//   background: rgba($info-base, 0.08);
// }
</style>
