import Api from "../../Shared/services/api";

const baseUrl = "UserInvitation";
export default {
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  accept(entity) {
    return Api().put(`${baseUrl}/Accept`, entity);
  },
};
